@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap';

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    // font-size: 62.5%;
    font-size: 80%;
    font-family: 'Roboto', sans-serif;
}

body {
    background: linear-gradient(180deg, rgb(0, 179, 254) 25%, rgb(0, 160, 234) 50%, rgb(0, 136, 210) 75%, rgb(0, 120, 191) 100%);

    #root {
        width: 100%;
        max-width: 800px;
        margin-inline: auto;
    }
}

.swiper {
    overflow: visible;
    margin-bottom: 2.5rem;
}

:root {
    --color-light: #fff;
}

.inover {
    background: #5b4681;
    background: linear-gradient(180deg, #5b4681 25%, #003c5f 100%) !important;
}

.inoversTopo {
    display: none !important;
}

.Toastify__toast-body > div:last-child {
    font-size: 1.4rem;
}

.spin {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    margin: 0 !important;
    height: 80%;
}

[disabled=disabled] {
    opacity: .6;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

