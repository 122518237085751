.menuHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    width: 100%;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.active {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 101;
            width: calc(100% - 2.5rem);
            padding: 1rem;

            .menuHamburguer {
                display: none;
            }
        }
    }

    .logo {
        svg {
            fill: var(--color-light);
            width: 9rem;
            height: auto;
            display: block;
        }
    }

    .menu {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        span {
            display: inline-block;
            font-size: 1.2rem;
            line-height: 1.4rem;
            overflow: hidden;
            color: var(--color-light);
            text-overflow: ellipsis;
            width: 8rem;
            white-space: nowrap;
            text-align: right;
        }

        .menuPicture {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 1rem;

            figure {
                width: 3rem;
                height: 3rem;
                overflow: hidden;
                border: 3px solid var(--color-light);
                border-radius: 2.5rem;
                position: relative;
                background: #ccc;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &:first-child {
                    transform: translateX(.7rem);
                    z-index: 2;
                }
            }
        }

        .menuSearch {
            display: flex;
            align-items: flex-end;
            margin-left: 1rem;
            svg {
                path {
                    stroke:none;
                    fill-rule:nonzero;
                    fill: #fff;
                    fill-opacity:1
                }
            }
        }

        .menuHamburguer {
            svg {
                width: 2rem;
                height: 2rem;
                fill: var(--color-light);
                margin-left: .5rem;
            }
        }
    }
}

.contagemInovers {
    border-bottom: 1px solid rgba(157, 214, 249, 1);
    padding: 2rem 0 1rem;
    margin-bottom: 1.5rem;

    .inoverHolder {
        width: 100%;
        height: .6rem;
        background: #fff;
        border-radius: 10px;
        position: relative;

        .inoverIndice {
            background: #00608b80;
            height: .6rem;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 30%;
            border-radius: 10px 0 0 10px;

            span {
                position: absolute;
                bottom: 1rem;
                left: 0;
                font-size: 10px;
                color: #fff;
                font-weight: 200;
                border-left: 1px solid #9DD6F9;
                display: inline-block;
                padding: .2rem 1rem;
                min-width: 10rem;
            }
        }

        .inoverMedia {
            background: #00608b80;
            height: .6rem;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 90%;
            border-radius: 10px 0 0 10px;

            span {
                position: absolute;
                bottom: 1rem;
                right: 0;
                font-size: 10px;
                color: #fff;
                font-weight: 200;
                border-right: 1px solid #9DD6F9;
                display: inline-block;
                padding: .2rem 1rem;
            }
        }
    }
}

.contagemHoras {
    border-bottom: 1px solid rgba(157, 214, 249, 1);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 1rem;
    margin-bottom: 1.5rem;

    p {
        font-size: 1rem;
        line-height: 1.2rem;
        color: var(--color-light);
        padding-bottom: .5rem;
        margin-right: 1.3rem;

        span {
            display: inline-block;
            border: 1px solid transparent;
            background: var(--color-light);
            color: rgba(0, 96, 139, 1);
            padding: .3rem 1rem;
            border-radius: .5rem;
            font-size: 1rem;
            line-height: 1.2rem;
            margin-right: 1rem;
        }
    }
}

.mainMenu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 96, 139, .7);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding: 1rem;

    .mainMenuButton {
        display: block;
        width: 100%;
        margin-bottom: 1rem;
        background: var(--color-light);
        border-radius: 4px;
        height: 4rem;
        line-height: 4rem;
        text-align: center;
        font-size: 1.6rem;
        text-decoration: none;
        color: #000;
    }

    .closeMainMenu {
        position: absolute;
        top: 1.8rem;
        right: 1.1rem;
        color: #fff;
    }
}