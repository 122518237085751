.content {
    background: rgb(0,179,254);
    background: linear-gradient(180deg, rgba(0,179,254,1) 25%, rgba(0,160,234,1) 50%, rgba(0,136,210,1) 75%, rgba(0,120,191,1) 100%);
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    min-height: 100vh;
    justify-content: flex-start;

    &.inovers {
        background: #5b4681;
        background: linear-gradient(180deg, #5b4681 25%, #003c5f 100%);
    }

    .menuHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .logo {

            svg {
                fill: var(--color-light);
                width: 9rem;
            }
        }

        .menu {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            span {
                display: inline-block;
                font-size: 1.2rem;
                line-height: 1.4rem;
                margin-right: 1.3rem;
                color: var(--color-light);
            }

            .menuPicture {
                display: flex;
                flex-direction: row;
                align-items: center;

                figure {
                    width: 3rem;
                    height: 3rem;
                    overflow: hidden;
                    border: 3px solid var(--color-light);
                    border-radius: 2.5rem;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    &:first-child {
                        transform: translateX(.7rem);
                        z-index: 2;
                    }
                }
            }

            .menuSearch {
                display: flex;
                align-items: flex-end;
                margin-left: 1rem;
                svg {
                    path {
                        stroke:none;
                        fill-rule:nonzero;
                        fill: #fff;
                        fill-opacity:1
                    }
                }
            }

            .menuHamburguer {
                svg {
                    width: 2rem;
                    height: 2rem;
                    fill: var(--color-light);
                    margin-left: .5rem;
                }
            }
        }
    }

    .contagemHoras {
        border-bottom: 1px solid rgba(157, 214, 249, 1);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0.5rem;

        p {
            font-size: 1rem;
            line-height: 1.2rem;
            color: var(--color-light);
            padding-bottom: .5rem;
            margin-right: 1.3rem;

            span {
                display: inline-block;
                border: 1px solid transparent;
                background: var(--color-light);
                color: rgba(0, 96, 139, 1);
                padding: .3rem 1rem;
                border-radius: .5rem;
                font-size: 1rem;
                line-height: 1.2rem;
                margin-right: 1rem;
            }
        }
    }

    ul {
        padding-left: 1.3rem;
        margin-bottom: 2rem;

        ul {
            padding-left: 2.3rem;
            margin-top: 1rem;
        }

        li {
            font-size: 1rem;
            line-height: 1.2rem;
            color: var(--color-light);
            padding-bottom: .5rem;
            margin-right: 1.3rem;

            a {
                color: var(--color-light);
            }
        }

        
    }

    .servicosDisponiveisDescription {
        margin-bottom: 3rem;

        span {
            font-size: 1rem;
            line-height: 1.5rem;
            color: var(--color-light);
            display: block;
        }

        .titleServices {
            width: 100%;

            span {
                font-size: 1.2rem;
                line-height: 1.4rem;
                color: var(--color-light);
                border-bottom: 1px solid rgba(157, 214, 249, 1);
                margin-bottom: 1rem;
                padding-bottom: .5rem;
                display: block;
            }

            p {
                font-size: .9rem;
                line-height: 1.2rem;
                color: var(--color-light);
                margin-bottom: 1rem;

                &:last-child {
                    margin-bottom: 2.5rem;
                }
            }
        }

        .servicesButton {
            text-align: center;
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 1.5rem;

            a {
                height: 3rem;
                line-height: 3rem;
                border-radius: 1rem;
                background: var(--color-light);
                color: rgba(0, 96, 139, 1);
                padding: 0 2.5rem;
                text-decoration: none;
                transition: all .1s ease-in-out;
                display: inline-block;

                &:hover {
                    background: rgba(0, 96, 139, 1);
                    color: var(--color-light);
                    text-decoration: none;
                }
            }
        }

        .passosStartup {
            display: grid;
            grid-template-columns: 20% 80%;
            gap: 0;
            margin-bottom: 3rem;

            .passosNumber {
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 1px solid var(--color-light);

                span {
                    font-size: 4rem;
                    line-height: 5rem;
                    color: var(--color-light);
                    margin: 0;
                }
            }

            .passosDescription {

                display: flex;
                align-items: center;
                margin-bottom: rem;
                padding-left: 1.5rem;

                p {
                    font-size: .9rem;
                    line-height: 1.2rem;
                    color: var(--color-light);
                    margin-bottom: 0;
                }
            }
        }
    }
}
