.content {
    background: rgb(0,179,254);
    background: linear-gradient(180deg, rgba(0,179,254,1) 25%, rgba(0,160,234,1) 50%, rgba(0,136,210,1) 75%, rgba(0,120,191,1) 100%);
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login {
        width: 100%;
        max-width: 500px;
    }

    .loginHeader {
        width: 100%;

        .loginTitle {
            margin-bottom: 5rem;
            text-align: center;
    
            svg {
                fill: var(--color-light);;
                width: 20rem;
            }
        }
    
        .loginDescription {
            margin-bottom: 4rem;
    
            p {
                font-size: 1.8rem;
                line-height: 2.2rem;
                font-weight: 400;
                color: var(--color-light);
                text-align: center;
            }
        }
    
        .loginForm {
            padding: 0 4rem;
            width: 100%;
    
            .loginEntrance {
    
                .inputBlock {
                    margin-top: 2rem;
    
                    input {
                        width: 100%;
                        border-radius: 2.5rem;
                        padding: 0 2rem;
                        border: 1px solid transparent;
                        background: var(--color-light);
                        height: 4.5rem;
                        line-height: 4.5rem;
                        color: #666;
                        font-size: 1.8rem;

                        &::placeholder {
                            color: #e0e0e0;
                        }

                        &:focus {
                            outline: none;;
                        }
                    }
    
                    span {
                        text-align: right;
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 2rem;
                        padding-top: .5rem;
                        font-size: 1.3rem;
                        line-height: 1.8rem;
                        color: var(--color-light);
                        text-decoration: none;
                        margin-bottom: 1rem;
                    }
                }
    
                .buttonForm {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
    
                    .buttonBlue {
                        display: inline-block;
                        height: 4rem;
                        line-height: 4rem;
                        border-radius: 2.5rem;
                        font-size: 1.7rem;
                        color: var(--color-light);
                        background: #005f8b;
                        text-align: center;
                        padding: 0 3rem;
                        border: 1px solid transparent;
                        transition: all .3s ease-in-out;
                        text-decoration: none;
                        margin-bottom: 1rem;
                        cursor: pointer;
                        width: 25rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        &:hover {
                            color: #005f8b;
                            background: var(--color-light);
                            text-decoration: none;
                        }
                    }

                    .buttonGoogle {
                        display: flex;
                        align-items: center;
                        height: 4rem;
                        line-height: 4rem;
                        border-radius: 2.5rem;
                        font-size: 1.7rem;
                        color: var(--color-light);
                        background: #ec4238;
                        text-align: center;
                        padding: 0 3rem;
                        border: 1px solid transparent;
                        transition: all .3s ease-in-out;
                        text-decoration: none;
                        margin-bottom: 1rem;
                        cursor: pointer;
                        width: 25rem;
    
                        &:hover {
                            color: #ec4238;
                            background: var(--color-light);
                            text-decoration: none;

                            svg {
                                path {
                                    fill: #ec4238;
                                }
                            }
                        }

                        svg {
                            margin-right: 1rem;
                        }
                    }
                }
            }
        }
    }

    .loginFooter {
        position: absolute;
        bottom: 3rem;
        left: 0;
        right: 0;
        margin: auto;

        a {
            display: block;
            font-size: 1.3rem;
            line-height: 1.8rem;
            color: var(--color-light);
            text-decoration: underline;
            text-align: center;
            transition: all .1s ease-in-out;

            &:hover {
                color: #005f8b;
                background: transparent;
            }
        }
    }
}