.content {
    background: rgb(0,179,254);
    background: linear-gradient(180deg, rgba(0,179,254,1) 25%, rgba(0,160,234,1) 50%, rgba(0,136,210,1) 75%, rgba(0,120,191,1) 100%);
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;

    .passaporteHeader {
        width: 100%;
        max-width: 500px;
        
        .passaporteTitle {
            margin-bottom: 1rem;
            text-align: center;
    
            svg {
                fill: var(--color-light);
                width: 20rem;
            }
        }
    
        .passaporteDescription {
            margin-bottom: 5rem;
    
            p {
                font-size: 1.8rem;
                line-height: 2.2rem;
                font-weight: 400;
                color: var(--color-light);
                text-align: center;
            }
        }  
    
        .passaporteForm {
            padding: 0 4rem;
            width: 100%;
    
            .passaporteEntrance {
    
                .inputBlock {
                    margin-top: 2rem;
                    position: relative;
    
                    input {
                        width: 100%;
                        padding: 0 3rem 0 0.5rem;
                        border-bottom: 1px solid rgba(157, 214, 249, 1);
                        border-top: none;
                        border-right: none;
                        border-left: none;
                        background: transparent;
                        height: 3.5rem;
                        line-height: 3.5rem;
                        color: #9dd6f9;
                        font-size: 1.4rem;
                        margin-top: .5rem;
    
                        &::placeholder {
                            color: #9dd6f9;
                        }
                    }
    
                    a {
                        text-align: right;
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 2rem;
                        padding-top: .5rem;
                        font-size: 1.3rem;
                        line-height: 1.8rem;
                        color: var(--color-light);
                        text-decoration: none;
                        margin-bottom: 1rem;
                    }
    
                    label {
                        font-size: 1rem;
                        line-height: 1.2rem;
                        color: var(--color-light);
                    }
    
                    svg {
                        fill: #9dd6f9;
                        width: 2rem;
                        position: absolute;
                        bottom: .8rem;
                        right: 0;
                    }
                }
    
                .checkbox {
                    margin-top: 3rem;
                    margin-bottom: 3rem;
    
                    .inputCheckbox {
                        display: grid;
                        grid-template-columns: .1fr .9fr;
                        margin-bottom: 1rem;
                        align-items: center;
    
                        input[type=radio] {
                            accent-color: #00608C;
                            width: 1.5rem;
                            height: 1.5rem;
                            border: 2px solid #9dd6f9;
                            background: none;
                            appearance: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            border-radius: 4px;
                        }

                        input[type=radio]:checked {
                            background: #9dd6f9;
                        }
    
                        span {
                            display: inline-block;
                            font-size: 1rem;
                            line-height: 1.2rem;
                            color: var(--color-light);
                        }
    
                    }
                }
    
                .buttonPassaporte {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
    
                    .buttonWhite {
                        display: inline-block;
                        height: 4rem;
                        line-height: 4rem;
                        border-radius: 2.5rem;
                        font-size: 1.7rem;
                        color: #005f8b;
                        background: var(--color-light);
                        text-align: center;
                        padding: 0 2.5rem;
                        border: 1px solid transparent;
                        transition: all .3s ease-in-out;
                        margin-bottom: 1.7rem;
                        text-decoration: none;
                        width: 100%;
    
                        &:hover {
                            color: var(--color-light);
                            background: #005f8b;
                            text-decoration: none;
                        }
                    }
    
                    p {
                        font-size: 1rem;
                        line-height: 1.2rem;
                        color: var(--color-light);
    
                        a {
                            text-decoration: underline;
                            transition: all .3s ease-in-out;
                            color: var(--color-light);
    
                            &:hover {
                                color: #005f8b;
                            }
                        }
                    }
                }
            }
        }
    }
}

