.content {
    background: rgb(0,179,254);
    background: linear-gradient(180deg, rgba(0,179,254,1) 25%, rgba(0,160,234,1) 50%, rgba(0,136,210,1) 75%, rgba(0,120,191,1) 100%);
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    min-height: 100vh;
    justify-content: space-between;

    &.inovers {
        background: #5b4681;
        background: linear-gradient(180deg, #5b4681 25%, #003c5f 100%);
    }

    .menuHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .logo {

            svg {
                fill: var(--color-light);
                width: 9rem;
            }
        }

        .menu {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            span {
                display: inline-block;
                font-size: 1.2rem;
                line-height: 1.4rem;
                margin-right: 1.3rem;
                color: var(--color-light);
            }

            .menuPicture {
                display: flex;
                flex-direction: row;
                align-items: center;

                figure {
                    width: 3rem;
                    height: 3rem;
                    overflow: hidden;
                    border: 3px solid var(--color-light);
                    border-radius: 2.5rem;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    &:first-child {
                        transform: translateX(.7rem);
                        z-index: 2;
                    }
                }
            }

            .menuSearch {
                display: flex;
                align-items: flex-end;
                margin-left: 1rem;
                svg {
                    path {
                        stroke:none;
                        fill-rule:nonzero;
                        fill: #fff;
                        fill-opacity:1
                    }
                }
            }

            .menuHamburguer {
                svg {
                    width: 2rem;
                    height: 2rem;
                    fill: var(--color-light);
                    margin-left: .5rem;
                }
            }
        }
    }

    .contagemHoras {
        border-bottom: 1px solid rgba(157, 214, 249, 1);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1.5rem;

        p {
            font-size: 1rem;
            line-height: 1.2rem;
            color: var(--color-light);
            padding-bottom: .5rem;
            margin-right: 1.3rem;

            span {
                display: inline-block;
                border: 1px solid transparent;
                background: var(--color-light);
                color: rgba(0, 96, 139, 1);
                padding: .3rem 1rem;
                border-radius: .5rem;
                font-size: 1rem;
                line-height: 1.2rem;
                margin-right: 1rem;
            }
        }
    }

    .servicosDisponiveisDescription {
        span {
            font-size: 1rem;
            line-height: 1.2rem;
            color: var(--color-light);
            margin-bottom: 1rem;
            display: block;
        }
    }

    .capacitacoesHolder {
        margin-top: 3rem;

        .capacitacoes {
            display: grid;
            grid-template-columns: 1fr 1fr;
        
            .capacitacoesThumb {
                width: 100%;
                height: 11rem;
                
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .capacitacoesInfos {
                flex: 1;
                margin-left: 1rem;
                
                span {
                    font-size: 1rem;
                    line-height: 1.4rem;
                    color: #fff;
                    font-weight: 200;
                    display: block;
                    margin-bottom: 0;
                }

                p {
                    font-size: 1rem;
                    line-height: 1.4rem;
                    color: #fff;
                    font-weight: 200;
                    display: block;
                    margin-top: 1rem;
                }
            }
        }

        .capacitacoesProgress {
            width: 100%;
            height: .6rem;
            background: #fff;
            border-radius: 10px;
            position: relative;

            div {
                background: #00608b;
                height: .6rem;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 30%;
                border-radius: 10px 0 0 10px;
            }
        }
    }

    .swiperNotices {
        .notice {
            border-radius: 1rem;
            background: #9DD6F975;
            padding: 2rem;
            align-items: center;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.5rem;

            .noticeImg {
                width: 100%;
                height: 100%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: .5rem;
                }
            }

            .noticeDescription {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                p {
                    font-size: 1rem;
                    line-height: 1.2rem;
                    color: var(--color-light);
                    margin-bottom: 1rem;
                }

                .buttonBlue {
                    height: 3rem;
                    line-height: 3rem;
                    color: var(--color-light);
                    background: #00608b;
                    font-size: 1rem;
                    border: 1px solid transparent;
                    border-radius: .5rem;
                    padding: 0 3rem;
                    text-decoration: none;
                    transition: all .1s ease-in-out;

                    &:hover {
                        color: #00608b;
                        background: var(--color-light);
                    }
                }
            }
        }
    }
}

.contentAdmin {
    justify-content: flex-start;

    header, .dashboardHolder  {
        max-width: 100%;
        width:  50rem;
        margin: 0 auto;

        
    }

    form {
        label {
            font-size: 1.3rem;
            display: block;
            color: #fff;
            margin-bottom: .5rem;
        }

        input {
            display: block;
            width: 100%;
            height: 3rem;
            line-height:3rem;
            padding: 0 1rem;
            border-radius: 40px;
            border: none;
            margin-bottom: 1rem;

            &:focus {
                outline: none;
            }
        }

        button {
            display: inline-block;
            height: 3rem;
            line-height: 3rem;
            border-radius: 2.5rem;
            font-size: 1.4rem;
            color: var(--color-light);
            background: #005f8b;
            text-align: center;
            padding: 0 3rem;
            border: 1px solid transparent;
            transition: all 0.3s ease-in-out;
            text-decoration: none;
            margin-bottom: 1rem;
            cursor: pointer;
            width: 100%;
            margin-top: 1rem;
        }
    }
}

.delete {
    position: absolute;
    top: -2.5rem;
    right: 0;

    button {
        color: #fff;
        width: auto;
        height: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        background: #ec4238;
        border: none;
        padding: 0 .5rem;
        font-size: .8rem;
    }
    
}
