.content {
    background: #5b4681;
    background: linear-gradient(180deg, rgba(0,179,254,1) 25%, rgba(0,160,234,1) 50%, rgba(0,136,210,1) 75%, rgba(0,120,191,1) 100%);
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    // height: 100vh;
    min-height: 100vh;
    height: auto;
    justify-content: space-between;
    overflow: hidden;

    &.inover {
        background: #5b4681;
        background: linear-gradient(180deg, #5b4681 25%, #003c5f 100%);
    }

    .listBox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.5rem;
        margin-bottom: 1.5rem;

        .box {
            height: 7rem;
            border: 1px solid transparent;
            background: #9DD6F975;
            border-radius: 1rem;

            a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                font-size: 1rem;
                line-height: 1.2rem;
                color: var(--color-light);
                text-decoration: none;
                text-align: center;
                position: relative;
                padding: .3rem;

                .star {
                    fill:#00608b;
                    opacity:0.51;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    width: 1rem;
                    height: 1rem;
                }

                svg {
                    width: 2.5rem;
                    fill:#00608b;
                    height: 2.5rem;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    .secondListBox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.5rem;
        margin-bottom: 1.5rem;

        .box {
            height: 7rem;
            border: 1px solid transparent;
            background: #9DD6F975;
            border-radius: 1rem;

            a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                font-size: 1rem;
                line-height: 1.2rem;
                color: var(--color-light);
                text-decoration: none;
                text-align: center;
                position: relative;

                .star {
                    fill:none;
                    stroke:#00608b;
                    stroke-miterlimit:10;
                    stroke-width:0.5px;
                    opacity:0.51;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    width: 1rem;
                    height: 1rem;
                }

                .iconEmpreendedor {
                    fill:none;
                    stroke:#00608b;
                    stroke-miterlimit:10;
                    stroke-width:2.2px;
                }

                svg {
                    width: 3.5rem;
                    fill:#00608b;
                    height: 3.5rem;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    .openCloseMenu {
        display: flex;
        width: 100%;
        padding: 0 2rem;
        justify-content: center;
        align-items: center;
        transform: rotate(180deg);

        span {
            svg {
                fill:none;
                stroke:#00608b;
                stroke-linecap:round;
                stroke-linejoin:round;
                stroke-width:2.08px;
                width: 4rem;
                height: 1.5rem;
            }
        }
    }

    .swiperNotices {
        .notice {
            border-radius: 1rem;
            background: #9DD6F975;
            padding: 2rem;
            align-items: center;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.5rem;

            .noticeImg {
                width: 100%;
                height: 100%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: .5rem;
                }
            }

            .noticeDescription {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                p {
                    font-size: 1rem;
                    line-height: 1.2rem;
                    color: var(--color-light);
                    margin-bottom: 1rem;
                }

                .buttonBlue {
                    height: 3rem;
                    line-height: 3rem;
                    color: var(--color-light);
                    background: #00608b;
                    font-size: 1rem;
                    border: 1px solid transparent;
                    border-radius: .5rem;
                    padding: 0 3rem;
                    text-decoration: none;
                    transition: all .1s ease-in-out;

                    &:hover {
                        color: #00608b;
                        background: var(--color-light);
                    }
                }
            }
        }
    }

    .news {
        border: 1px solid #9dd6f9;
        border-radius: 10px;
        padding: .5rem 1rem;
        padding-right: 4rem;
        position: relative;
        margin-bottom: .5rem;

        a {
            text-decoration: none;
        }

        svg {
            position: absolute;
            right: 2rem;
            bottom: 0;
            top: 0;
            margin: auto;
            width: 12px;
        }

        .newsTitle {
            font-size: 1.2rem;
            line-height: 1.4rem;
            color: #fff;
            font-weight: 600;
            display: block;
            margin-bottom: .5rem;
        }

        .newsDescription {
            color: #fff;
            font-size: 1rem;
            line-height: 1.2rem;
            font-weight: 200;
        }
    }
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: -20px;
}

.swiper-pagination-bullet-active {
    background: #fff;
}